import axios from 'axios';
import authHeader from './auth-header';
const API_URL = process.env.VUE_APP_TRIPTRADER_API_HOST;
class ContactService {

  getMyContacts() {
    return axios.get(API_URL + 'my/contacts',{ headers: authHeader() }).then(response => {
      console.log("getMyContacts response: " + JSON.stringify(response.data));
      if(response.data.status == "failed") {
        console.log("Response has  NO token:" );
        // localStorage.setItem('user', null);
        // localStorage.removeItem('user');
      }
      return response.data;
    });
  }
  getContact(id) {
    return axios.get(API_URL + '/contacts/'+id,{ headers: authHeader() }).then(response => {
      console.log("getContact response: " + JSON.stringify(response.data));
      if(response.data.status == "failed") {
        console.log("Response has  NO token:" );
        // localStorage.setItem('user', null);
        // localStorage.removeItem('user');
      }
      return response.data;
    });
  }
 
  createContact(contact) {
    return axios
    .post(API_URL + '/my/contacts/create', contact,{ headers: authHeader() } )
    .then(response => {
      console.log("response: " + JSON.stringify(response.data));
      return response.data;
    });
  }
  saveContact(contact) {
    return axios
    .post(API_URL + '/contacts', contact,{ headers: authHeader() } )
    .then(response => {
      console.log("response: " + JSON.stringify(response.data));
      return response.data;
    });
  }
 
}
export default new ContactService();